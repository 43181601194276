export default {
  Simple: {
    heading: `Simple, right out of the box`,
  },
  'Getting started': {
    heading: `Getting started`,
    content: `Once you’ve received your Aidia Smart Bottle, you are on your way to staying on track with your medication schedule.`,
  },
  Check: {
    heading: `Check`,
    content: `Your pharmacy may have already put your medication in the bottle. If not, simply transfer your medication from your regular pharmacy bottle into your Aidia Smart Bottle.`,
  },
  Activate: {
    heading: `Activate`,
    content: `Remove the orange pull-tab from the Aidia Smart Bottle (if the pharmacy hasn’t already).`,
  },
  Relax: {
    heading: `Relax`,
    content: `Your Aidia Smart Bottle arrives charged and pre-set to your dosing schedule. It works automatically and will alert you when it’s time for your next dose.`,
  },
  Save: {
    heading: `Save`,
    content: `Be sure to keep the smart bottle charger for future use.`,
  },
  'Dose time alerts': {
    heading: `How dose time alerts work`,
  },
  'Before dose time': {
    heading: `Before your dose time`,
    content: `One hour before your dose time, the bottle glows blue to alert you to your upcoming dose time.`,
  },
  'At dose time': {
    heading: `At your dose time`,
    content: `At your dose time, if you have not already opened the bottle, it will begin to chime and you may receive a message by text or call. The bottle will light up and chime every 15 minutes for an hour, or until you open the bottle to take your medication.`,
  },
  'After dose time': {
    heading: `After your dose time`,
    content: `60-90 minutes after your dose time, if you have not opened the bottle, you may receive additional messages depending on your medication.`,
  },
  'Battery life': {
    heading: `Battery life`,
    content: `Your smart bottle arrives charged. At full charge, the bottle has enough power for up to 10 months. You will receive a low battery message by text or call when it’s time to recharge.`,
  },
  Help: {
    heading: `Help is at your fingertips`,
    content: `If at any point you’d like to change your bottle’s settings or dose schedule, call Aidia Smart Bottle Support at 1-800-381-9384.`,
    mon: `Monday–Thursday (8am - 8pm ET)`,
    fri: `Friday (8am - 6pm ET)`,
    sat: `Saturday–Sunday (11am - 5pm ET)`,
  },
  Help_onco: {
    heading: `Help is at your fingertips`,
    content: `If at any point you’d like to change your bottle’s settings or dose schedule, contact Onco360 Oncology Pharmacy at (877) 662-6633.`,
  },
  Questions: {
    heading: `Questions?`,
    content: `Check out these frequently asked questions.`,
    cta: `View FAQs`,
  },
}
