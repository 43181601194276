import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { getSearchParams } from 'gatsby-query-params'

import LanguageTabs, { useLang } from '../components/LanguageTabs'
import gettingStarted_EN from '../content/landingPages/gettingStarted_EN'
import gettingStarted_ES from '../content/landingPages/gettingStarted_ES'
import iconReminder from '../images/list-icons/icon-reminder.svg'
import iconBottle from '../images/list-icons/icon-bottle.svg'
import iconMessage from '../images/list-icons/icon-message.svg'
import NumberedList from '../components/NumberedList'
import CallToActionLink from '../components/CallToActionLink'

const content = {
  EN: gettingStarted_EN,
  ES: gettingStarted_ES,
}

const GettingStartedLandingPage = () => {
  const queryParams = getSearchParams()
  const { sp } = queryParams
  const lang = useLang(content)

  const getFAQUrl = () => {
    const paramsStr = new URLSearchParams(queryParams).toString()
    return paramsStr ? `/faqs-bottle?${paramsStr}` : '/faqs-bottle'
  }

  return (
    <main className="gettingstarted-landing-page">
      <div className="welcome-hero">
        <div className="welcome-hero-text">{lang('Simple').heading}</div>
      </div>

      <div className="landing-page-section">
        <div className="landing-page-section-content">
          <LanguageTabs />
          <div className="intro">
            <h2>{lang('Getting started').heading}</h2>
            <p>{lang('Getting started').content}</p>
          </div>

          <div className="numbered-list-container">
            <div className="left-column">
              <NumberedList
                textElements={[
                  <>
                    <h3>{lang('Check').heading}</h3>
                    <p>{lang('Check').content}</p>
                  </>,
                  <>
                    <h3>{lang('Activate').heading}</h3>
                    <p>{lang('Activate').content}</p>
                  </>,
                  <>
                    <h3>{lang('Relax').heading}</h3>
                    <p>{lang('Relax').content}</p>
                  </>,
                  <>
                    <h3>{lang('Save').heading}</h3>
                    <p>{lang('Save').content}</p>
                  </>,
                ]}
              />
            </div>
            <div className="right-column">
              <StaticImage
                src="../images/pull-tab-photo.png"
                alt="Pull tab"
                placeholder="none"
                layout="constrained"
                height={264}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="landing-page-section-dark">
        <div className="landing-page-section-content">
          <div className="dose-time-alerts">
            <div className="left-column">
              <h2>{lang('Dose time alerts').heading}</h2>
              <div className="icon-list-item">
                <img src={iconReminder} alt="reminder" />
                <div className="icon-list-text">
                  <h3>{lang('Before dose time').heading}</h3>
                  <p>{lang('Before dose time').content}</p>
                </div>
              </div>
              <div className="icon-list-item">
                <img src={iconBottle} alt="bottle" />
                <div className="icon-list-text">
                  <div>
                    <h3>{lang('At dose time').heading}</h3>
                    <p>{lang('At dose time').content}</p>
                  </div>
                </div>
              </div>
              <div className="icon-list-item">
                <img src={iconMessage} alt="message" />
                <div className="icon-list-text">
                  <h3>{lang('After dose time').heading}</h3>
                  <p>{lang('After dose time').content}</p>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="right-column">
              <div className="battery-life">
                <h2>{lang('Battery life').heading}</h2>
                <p>{lang('Battery life').content}</p>
              </div>
              {sp === 'onco' ? (
                <div className="help">
                  <h2>{lang(`Help_${sp}`).heading}</h2>
                  <p>{lang(`Help_${sp}`).content}</p>
                </div>
              ) : (
                <div className="help">
                  <h2>{lang('Help').heading}</h2>
                  <p>
                    {lang('Help').content}
                    <br />
                    <br />
                    {lang('Help').mon}
                    <br />
                    {lang('Help').fri}
                    <br />
                    {lang('Help').sat}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="faqs-wrapper">
        <div className="faqs">
          <h3>{lang('Questions').heading}</h3>
          <p>{lang('Questions').content}</p>
          <CallToActionLink linkText={lang('Questions').cta} linkUrl={getFAQUrl()} />
        </div>
      </div>
    </main>
  )
}

export default GettingStartedLandingPage
