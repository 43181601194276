export default {
  Simple: {
    heading: `Simple, desde el primer momento`,
  },
  'Getting started': {
    heading: `Comienzo`,
    content: `Una vez que haya recibido su frasco inteligente Aidia, comenzará a mantenerse al día con sus horarios de medicamentos.`,
  },
  Check: {
    heading: `Cheque`,
    content: `Es posible que su farmacia ya haya colocado su medicamento dentro del frasco. De lo contrario, simplemente transfiera su medicamento del frasco regular a su frasco inteligente Aidia.`,
  },
  Activate: {
    heading: `Active`,
    content: `Retire la etiqueta naranja del frasco inteligente Aidia (si su farmacia aún no lo ha hecho).`,
  },
  Relax: {
    heading: `Relájese`,
    content: `Su frasco inteligente Aidia llega cargado y preajustado a su horario de dosificación. Funciona automáticamente y le avisará cuando sea hora de su próxima dosis.`,
  },
  Save: {
    heading: `Guárdelo`,
    content: `Asegúrese de guardar el cargador del frasco inteligente para su uso futuro.`,
  },
  'Dose time alerts': {
    heading: `Cómo funcionan las alertas de la dosis`,
  },
  'Before dose time': {
    heading: `Antes de la hora de su dosis`,
    content: `Una hora antes de la hora de su dosis, el frasco se iluminará de azul para avisarle que se aproxima la hora de su dosis.`,
  },
  'At dose time': {
    heading: `A la hora de su dosis`,
    content: `A la hora de su dosis si aún no ha abierto el frasco, comenzará a sonar y es posible que reciba un mensaje de texto o llamada. El frasco se iluminará y sonará cada 15 minutos durante una hora, o hasta que abra el frasco para tomar su medicamento.`,
  },
  'After dose time': {
    heading: `Después de la hora de su dosis`,
    content: `60-90 minutos después de la hora de su dosis, si no ha abierto el frasco, puede recibir mensajes adicionales dependiendo de su medicamento.`,
  },
  'Battery life': {
    heading: `Duración de la batería`,
    content: `Su frasco inteligente llega cargado. Con carga completa, el frasco tiene batería suficiente para hasta 10 meses. Recibirá un mensaje de batería baja por texto o llamada cuando sea el momento de recargar.`,
  },
  Help: {
    heading: `Ayuda al alcance de su mano`,
    content: `Si en algún momento desea cambiar la configuración de su frasco o el horario de su dosis, llame al servicio de asistencia al 1-800-381-9384.`,
    mon: `lunes a jueves (8 am a 8 pm ET)`,
    fri: `viernes (8 am a 6 pm ET)`,
    sat: `sábado–domingo (11 am a 5 pm ET)`,
  },
  Help_onco: {
    heading: `Ayuda al alcance de su mano`,
    content: `Su farmacia está aquí para ayudar. Pueden actualizar sus tiempos de dosis, pausar recordatorios y personalizar la configuración de su frasco o teléfono. Simplemente llame a la farmacia Onco360 Oncology al (877) 662-6633.`,
  },
  Questions: {
    heading: `¿Preguntas?`,
    content: `Consulte estas preguntas frecuentes.`,
    cta: `Ver preguntas frecuentes`,
  },
}
